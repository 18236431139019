@import "~antd/dist/antd.less";

// Overwrite of Antd default theme variables
// Full list of variables:
// sofia_client/node_modules/antd/lib/style/themes/default.less
// -----------------------------------------------------------------------------
@primary-color: #4a25aa;
@layout-header-background: #5e32d2;
// -----------------------------------------------------------------------------

html,
body {
  margin: 0;
  padding: 0;
  background-color: @white;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 200px;
  height: auto;
  margin: 0 8px 8px 0;
}
